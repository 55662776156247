import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const meta = {
  title: "",
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Index() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        {/* <section className="overflow-hidden">
          <nav className="relative px-10 py-7 pb-7">
            <div className="flex items-center justify-between">
              <a className="relative z-10 pr-4 lg:pr-0" href="#">
                <img
                  className="h-14"
                  src="images/1xSkills-Logo-png-1709229494497.webp"
                  alt=""
                />
              </a>
              <div className="hidden xl:absolute xl:top-1/2 xl:left-1/2 xl:transform xl:-translate-y-1/2 xl:-translate-x-1/2 xl:flex lg:justify-center gap-7">
                <a
                  className="flex gap-7 items-center text-lg tracking-tight font-medium hover:text-opacity-80 transition duration-200"
                  href="#"
                >
                  <span>What is 1xSkills</span>
                  <div className="w-1.5 h-1.5 bg-neutral-100 rounded-full" />
                </a>
                <a
                  className="flex gap-7 items-center text-lg tracking-tight font-medium hover:text-opacity-80 transition duration-200"
                  href="#"
                >
                  <span>Features</span>
                  <div className="w-1.5 h-1.5 bg-neutral-100 rounded-full" />
                </a>
                <a
                  className="flex gap-7 items-center text-lg tracking-tight font-medium hover:text-opacity-80 transition duration-200"
                  href="#"
                >
                  <span>Roadmap</span>
                  <div className="w-1.5 h-1.5 bg-neutral-100 rounded-full" />
                </a>
                <a
                  className="flex gap-7 items-center text-lg tracking-tight font-medium hover:text-opacity-80 transition duration-200"
                  href="#"
                >
                  <span>Courses</span>
                  <div className="w-1.5 h-1.5 bg-neutral-100 rounded-full" />
                </a>
                <a
                  className="flex items-center text-lg tracking-tight font-medium hover:text-opacity-80 transition duration-200"
                  href="#"
                >
                  <span>Resources</span>
                </a>
              </div>
              <div className="hidden xl:flex items-center gap-2">
                <a className="hidden 2xl:block mr-8" href="#">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={20}
                    height={20}
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_231_6677)">
                      <path
                        d="M19.6343 17.871L15.7634 13.9984C18.6598 10.1278 17.87 4.64195 13.9994 1.74551C10.1288 -1.15092 4.64292 -0.361157 1.74649 3.50949C-1.14994 7.38013 -0.36018 12.866 3.51046 15.7624C6.61969 18.0891 10.8901 18.0891 13.9994 15.7624L17.872 19.635C18.3587 20.1216 19.1477 20.1216 19.6343 19.635C20.121 19.1483 20.121 18.3593 19.6343 17.8727L19.6343 17.871ZM8.7872 15.015C5.34716 15.015 2.55848 12.2263 2.55848 8.78623C2.55848 5.34618 5.34716 2.55751 8.7872 2.55751C12.2273 2.55751 15.0159 5.34618 15.0159 8.78623C15.0123 12.2247 12.2257 15.0113 8.7872 15.015Z"
                        fill="#19191B"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_231_6677">
                        <rect width={20} height={20} fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
                <a
                  className="inline-flex justify-center items-center text-center h-16 p-5 font-semibold tracking-tight text-xl hover:text-white focus:text-white bg-transparent hover:bg-neutral-900 focus:bg-neutral-900 border border-neutral-900 rounded-lg focus:ring-4 focus:ring-neutral-400 transition duration-200"
                  href="#"
                >
                  Login
                </a>
                <a
                  className="inline-flex justify-center items-center text-center h-16 p-5 font-semibold tracking-tight text-xl hover:text-white focus:text-white bg-transparent hover:bg-neutral-900 focus:bg-neutral-900 border border-neutral-900 rounded-lg focus:ring-4 focus:ring-neutral-400 transition duration-200"
                  href="#"
                >
                  Sign up free
                </a>
              </div>
              <a className="navbar-burger xl:hidden" href="#">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={34}
                  height={14}
                  viewBox="0 0 34 14"
                  fill="none"
                >
                  <g clipPath="url(#clip0_231_4713)">
                    <rect width={34} height={3} rx="1.5" fill="#19191B" />
                    <rect
                      y={11}
                      width={34}
                      height={3}
                      rx="1.5"
                      fill="#19191B"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_231_4713">
                      <rect width={34} height={14} fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </div>
          </nav>
          <div className="navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-xs z-50 hidden">
            <div className="navbar-backdrop fixed inset-0 bg-black opacity-20" />
            <nav className="relative p-8 w-full h-full bg-white overflow-y-auto">
              <div className="flex flex-col justify-between h-full">
                <div>
                  <div className="flex items-center justify-between mb-8">
                    <a className="pr-4" href="#">
                      <img
                        className="h-10"
                        src="images/1xSkills-Logo-png-1709229494497.webp"
                        alt=""
                      />
                    </a>
                    <a className="navbar-close" href="#">
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6 18L18 6M6 6L18 18"
                          stroke="#252E4A"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </a>
                  </div>
                  <a href="#">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={20}
                      height={20}
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_231_6677)">
                        <path
                          d="M19.6343 17.871L15.7634 13.9984C18.6598 10.1278 17.87 4.64195 13.9994 1.74551C10.1288 -1.15092 4.64292 -0.361157 1.74649 3.50949C-1.14994 7.38013 -0.36018 12.866 3.51046 15.7624C6.61969 18.0891 10.8901 18.0891 13.9994 15.7624L17.872 19.635C18.3587 20.1216 19.1477 20.1216 19.6343 19.635C20.121 19.1483 20.121 18.3593 19.6343 17.8727L19.6343 17.871ZM8.7872 15.015C5.34716 15.015 2.55848 12.2263 2.55848 8.78623C2.55848 5.34618 5.34716 2.55751 8.7872 2.55751C12.2273 2.55751 15.0159 5.34618 15.0159 8.78623C15.0123 12.2247 12.2257 15.0113 8.7872 15.015Z"
                          fill="#19191B"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_231_6677">
                          <rect width={20} height={20} fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                </div>
                <div className="flex flex-col gap-8 py-16">
                  <a
                    className="inline-block max-w-max text-lg tracking-tight font-medium hover:text-opacity-80 transition duration-200"
                    href="#"
                  >
                    What is 1xSkills
                  </a>
                  <a
                    className="inline-block max-w-max text-lg tracking-tight font-medium hover:text-opacity-80 transition duration-200"
                    href="#"
                  >
                    Features
                  </a>
                  <a
                    className="inline-block max-w-max text-lg tracking-tight font-medium hover:text-opacity-80 transition duration-200"
                    href="#"
                  >
                    Roadmap
                  </a>
                  <a
                    className="inline-block max-w-max text-lg tracking-tight font-medium hover:text-opacity-80 transition duration-200"
                    href="#"
                  >
                    Course Catalog
                  </a>
                  <a
                    className="inline-block max-w-max text-lg tracking-tight font-medium hover:text-opacity-80 transition duration-200"
                    href="#"
                  >
                    Resources
                  </a>
                </div>
                <div className="flex flex-col items-center gap-2">
                  <a
                    className="inline-flex justify-center items-center text-center w-full h-16 p-5 font-semibold tracking-tight text-lg hover:text-white focus:text-white bg-transparent hover:bg-neutral-900 focus:bg-neutral-900 border border-neutral-900 rounded-lg focus:ring-4 focus:ring-neutral-400 transition duration-200"
                    href="#"
                  >
                    Login
                  </a>
                  <a
                    className="inline-flex justify-center items-center text-center w-full h-16 p-5 font-semibold tracking-tight text-lg hover:text-white focus:text-white bg-transparent hover:bg-neutral-900 focus:bg-neutral-900 border border-neutral-900 rounded-lg focus:ring-4 focus:ring-neutral-400 transition duration-200"
                    href="#"
                  >
                    Sign up free
                  </a>
                </div>
              </div>
            </nav>
          </div>
        </section> */}
        {/* <Navbar /> */}
        <section className="overflow-hidden">
          <div className="flex flex-wrap">
            <div className="w-full md:w-1/2 relative" style={{ height: 860 }}>
              <img
                className="absolute top-0 left-0 w-full h-full object-cover"
                src="mirga-assets/images/hero/background2.png"
                alt=""
              />
              <div className="absolute left-5 sm:left-10 right-5 sm:right-10 bottom-10">
                <h1 className="font-heading mb-8 text-6xl sm:text-9xl xl:text-10xl text-white font-semibold max-w-4xl">
                  <span>Learn, practice and</span>
                  <span className="relative inline-block">
                    <span className="relative z-10">dominate</span>
                    <span className="absolute -bottom-2 left-0 h-1 w-full bg-green-200 rounded-full" />
                  </span>
                  <span> world.</span>
                </h1>
                <p className="text-xl font-medium text-white mb-10 tracking-tight">
                  Your journey to career excellence starts here.
                </p>
                <a
                  className="inline-flex justify-center items-center text-center h-20 p-5 font-semibold tracking-tight text-2xl text-white bg-neutral-900 hover:bg-neutral-800 focus:bg-neutral-800 rounded-lg focus:ring-4 focus:ring-neutral-400 transition duration-200"
                  href="#"
                >
                  More
                </a>
              </div>
            </div>
            <div className="w-full md:w-1/2 relative" style={{ height: 860 }}>
              <img
                className="absolute top-0 left-0 w-full h-full object-cover"
                src="mirga-assets/images/hero/background3.png"
                alt=""
              />
              <div className="absolute left-5 sm:left-10 right-5 sm:right-10 bottom-10">
                <div className="flex items-end justify-between flex-wrap gap-6">
                  <div className="flex items-start flex-wrap gap-6">
                    <div className="relative">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={48}
                        height={48}
                        viewBox="0 0 48 48"
                        fill="none"
                      >
                        <circle cx={24} cy={24} r={24} fill="white" />
                      </svg>
                      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={20}
                          height={20}
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_231_4799)">
                            <path
                              d="M17.9176 11.7658L15.0151 10L17.9176 8.23417C18.2196 8.05332 18.4825 7.81416 18.691 7.53063C18.8995 7.24711 19.0495 6.92489 19.1322 6.58279C19.2149 6.24069 19.2286 5.88554 19.1726 5.53808C19.1166 5.19061 18.9919 4.85778 18.8059 4.559C18.6199 4.26022 18.3762 4.00148 18.0891 3.79786C17.8021 3.59425 17.4773 3.44984 17.1338 3.37306C16.7904 3.29628 16.435 3.28866 16.0886 3.35066C15.7421 3.41266 15.4115 3.54303 15.116 3.73417L12.501 5.40833V2.5C12.501 1.83696 12.2376 1.20107 11.7687 0.732233C11.2999 0.263392 10.664 0 10.001 0C9.33793 0 8.70205 0.263392 8.23321 0.732233C7.76437 1.20107 7.50098 1.83696 7.50098 2.5V5.49L4.88597 3.73417C4.28962 3.37698 3.57683 3.26834 2.90114 3.43164C2.22546 3.59495 1.64096 4.01713 1.27356 4.60725C0.906154 5.19736 0.785255 5.90818 0.936903 6.58658C1.08855 7.26498 1.50061 7.85666 2.08431 8.23417L4.98681 10L2.08431 11.7658C1.50061 12.1433 1.08855 12.735 0.936903 13.4134C0.785255 14.0918 0.906154 14.8026 1.27356 15.3927C1.64096 15.9829 2.22546 16.4051 2.90114 16.5684C3.57683 16.7317 4.28962 16.623 4.88597 16.2658L7.50098 14.5675V17.5C7.50098 18.163 7.76437 18.7989 8.23321 19.2678C8.70205 19.7366 9.33793 20 10.001 20C10.664 20 11.2999 19.7366 11.7687 19.2678C12.2376 18.7989 12.501 18.163 12.501 17.5V14.5167L15.116 16.2667C15.7123 16.6239 16.4251 16.7325 17.1008 16.5692C17.7765 16.4059 18.361 15.9837 18.7284 15.3936C19.0958 14.8035 19.2167 14.0927 19.065 13.4143C18.9134 12.7359 18.5013 12.1433 17.9176 11.7658Z"
                              fill="#19191B"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_231_4799">
                              <rect width={20} height={20} fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </div>
                    <h6 className="font-heading text-white text-2xl sm:text-4xl tracking-tight font-semibold w-56 sm:w-auto sm:max-w-xs">
                      Enage in community learning to be on top.
                    </h6>
                  </div>
                  <a
                    className="flex items-center flex-wrap gap-3 group"
                    href="#"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={27}
                      viewBox="0 0 24 27"
                      fill="none"
                    >
                      <path
                        d="M22.5 10.9019C24.5 12.0566 24.5 14.9434 22.5 16.0981L5.24999 26.0574C3.24999 27.2121 0.749999 25.7687 0.749999 23.4593L0.75 3.5407C0.75 1.2313 3.25 -0.212069 5.25 0.942631L22.5 10.9019Z"
                        fill="#7CFF8F"
                      />
                    </svg>
                    <h6 className="font-heading text-white text-xl font-semibold tracking-tight group-hover:text-opacity-80 transition duration-200">
                      Watch video
                    </h6>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="pt-14 pb-24"
            style={{
              background:
                "linear-gradient(131deg, #E8E5FE 26.82%, #FFEED8 96.89%)",
            }}
          >
            <div className="container mx-auto px-4">
              <div className="flex flex-wrap max-w-7xl mx-auto -m-3">
                <div className="w-full md:w-1/2 p-3">
                  <div className="bg-white rounded-4xl p-10 h-full lg:h-60">
                    <div className="flex flex-wrap -m-3">
                      <div className="w-full lg:w-1/2 p-3">
                        <div className="flex flex-col justify-between gap-6 xl:gap-12">
                          <h6 className="font-heading text-4xl font-semibold max-w-xs tracking-tight">
                            Choose from a list of 1000+ courses
                          </h6>
                          <a
                            className="inline-flex items-center gap-3 group mr-auto"
                            href="#"
                          >
                            <span className="text-neutral-600 font-semibold group-hover:text-neutral-800 tracking-tight transition duration-200">
                              Learn more now
                            </span>
                            <div className="w-6 h-6 rounded-full border border-neutral-50 group-hover:border-neutral-200 transition duration-200 flex items-center justify-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={12}
                                height={12}
                                viewBox="0 0 12 12"
                                fill="none"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M6 1L5.2825 1.6965L9.086 5.5H1V6.5H9.086L5.293 10.293L6 11L11 6L6 1Z"
                                  fill="#19191B"
                                />
                              </svg>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div className="w-full lg:w-1/2 p-3">
                        <img
                          className="mx-auto object-cover lg:mr-0 h-44"
                          src="mirga-assets/images/hero/circle2.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-1/2 p-3">
                  <div className="bg-white rounded-4xl p-10 h-full lg:h-60">
                    <div className="flex flex-wrap -m-3">
                      <div className="w-full lg:w-1/2 p-3">
                        <div className="flex flex-col justify-between gap-6 xl:gap-12">
                          <h6 className="font-heading text-4xl font-semibold max-w-xs tracking-tight">
                            Learning plan that works for you.
                          </h6>
                          <a
                            className="inline-flex items-center gap-3 group mr-auto"
                            href="#"
                          >
                            <span className="text-neutral-600 font-semibold group-hover:text-neutral-800 tracking-tight transition duration-200">
                              Learn more now
                            </span>
                            <div className="w-6 h-6 rounded-full border border-neutral-50 group-hover:border-neutral-200 transition duration-200 flex items-center justify-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={12}
                                height={12}
                                viewBox="0 0 12 12"
                                fill="none"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M6 1L5.2825 1.6965L9.086 5.5H1V6.5H9.086L5.293 10.293L6 11L11 6L6 1Z"
                                  fill="#19191B"
                                />
                              </svg>
                            </div>
                          </a>
                        </div>
                      </div>
                      <div className="w-full lg:w-1/2 p-3">
                        <img
                          className="mx-auto object-cover lg:mr-0 h-44"
                          src="mirga-assets/images/hero/diagram.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-neutral-50 py-16">
          <div className="container mx-auto px-4">
            <p className="text-xl font-medium mb-14 text-center">
              Trusted by top brands
            </p>
            <div className="flex flex-wrap items-center justify-center -m-8">
              <div className="w-auto p-8">
                <img
                  className="h-9 mix-blend-multiply"
                  src="mirga-assets/images/logos/placeholder-logo-light1.svg"
                  alt=""
                />
              </div>
              <div className="w-auto p-8">
                <img
                  className="h-9"
                  src="mirga-assets/images/logos/placeholder-logo-light2.svg"
                  alt=""
                />
              </div>
              <div className="w-auto p-8">
                <img
                  className="h-9"
                  src="mirga-assets/images/logos/placeholder-logo-light3.svg"
                  alt=""
                />
              </div>
              <div className="w-auto p-8">
                <img
                  className="h-9"
                  src="mirga-assets/images/logos/placeholder-logo-light4.svg"
                  alt=""
                />
              </div>
              <div className="w-auto p-8">
                <img
                  className="h-9"
                  src="mirga-assets/images/logos/placeholder-logo-light5.svg"
                  alt=""
                />
              </div>
              <div className="w-auto p-8">
                <img
                  className="h-9"
                  src="mirga-assets/images/logos/placeholder-logo-light6.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
        <section className="py-12 md:py-24 px-4 md:px-12 bg-neutral-50">
          <div className="bg-white rounded-3xl py-8 sm:py-14 md:py-18 px-4 sm:px-8 md:px-16">
            <div className="flex flex-wrap items-end -m-4 mb-12">
              <div className="w-full lg:w-3/4 p-4">
                <img
                  className="rounded-4xl object-cover w-full"
                  style={{ height: 666 }}
                  src="mirga-assets/images/about/picture1.png"
                  alt=""
                />
              </div>
              <div className="w-full lg:w-1/4 p-4">
                <img
                  className="rounded-4xl object-cover w-full"
                  style={{ height: 415 }}
                  src="mirga-assets/images/about/picture2.png"
                  alt=""
                />
              </div>
            </div>
            <div className="container mx-auto px-4">
              <div className="md:max-w-6xl mx-auto">
                <h1 className="font-heading mb-16 text-6xl sm:text-9xl xl:text-11xl text-neutral-800 font-semibold ">
                  <span className="inline-block">Building better&nbsp;</span>
                  <span className="inline-block">
                    &nbsp;learning expericnes.
                  </span>
                  <div className="pl-8 sm:pl-14 lg:pl-28">
                    <span>This is our life.</span>
                  </div>
                </h1>
                <div className="pl-8 sm:pl-14 lg:pl-28">
                  <h2 className="text-neutral-600 text-2xl font-semibold mb-20 tracking-tight font-heading">
                    Be 1x better every day with us.
                  </h2>
                  <div className="flex flex-wrap gap-12">
                    <p className="text-neutral-600 text-lg font-medium max-w-xs tracking-tight">
                      We've been sharing our passion for digital education with
                      the world. Join us and experience the joy of learning like
                      never before.
                    </p>
                    <p className="text-neutral-600 text-lg font-medium max-w-xs tracking-tight">
                      For over&nbsp; years, we've been sharing our passion for
                      digital education with the world. Join us and experience
                      the joy of learning like never before.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="py-12 md:py-24 xl:pb-64 bg-neutral-900">
          <div className="container mx-auto px-4">
            <div className="mb-12 md:mb-20 max-w-5xl">
              <h1 className="relative text-6xl sm:text-9xl xl:text-11xl text-white font-semibold font-heading">
                <span className="mr-3">Learn, practice, and</span>
                <span className="relative inline-block">
                  <span className="relative z-10">Dominate World</span>
                  <span className="absolute -bottom-2 left-0 h-2.5 w-full bg-green-100 rounded-full" />
                </span>
              </h1>
            </div>
            <h3 className="mb-8 text-4xl text-white font-semibold tracking-tight font-heading">
              Offerings that boosts your career.
            </h3>
            <div className="flex flex-wrap -m-3">
              <div className="w-full md:w-1/2 xl:w-1/3 p-3">
                <div className="flex flex-col h-full justify-center p-10 bg-white rounded-5xl">
                  <div className="flex flex-wrap items-center -m-3 mb-5">
                    <div className="w-auto p-3">
                      <div className="flex items-center justify-center w-16 h-16 bg-neutral-900 rounded-full">
                        <img
                          src="mirga-assets/images/features/sticker.svg"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="flex-1 p-3">
                      <h4 className="text-4xl font-medium tracking-tight font-heading max-w-xs">
                        Curated courses based on your career goals
                      </h4>
                    </div>
                  </div>
                  <p className="text-neutral-600 font-medium tracking-tight max-w-sm">
                    Tailor your marketing approach with our customizable
                    campaigns. We work closely with you to understand your
                    unique goals and audience, designing personalized strategies
                    that ensure your message reaches the right people at the
                    right time.
                  </p>
                </div>
              </div>
              <div className="relative xl:top-20 w-full md:w-1/2 xl:w-1/3 p-3">
                <div className="mb-6 p-10 bg-white rounded-5xl">
                  <div className="flex flex-wrap items-center -m-3 mb-5">
                    <div className="w-auto p-3">
                      <div className="flex items-center justify-center w-16 h-16 bg-neutral-900 rounded-full">
                        <img
                          src="mirga-assets/images/features/sticker.svg"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="flex-1 p-3">
                      <h4 className="text-4xl font-medium tracking-tight font-heading max-w-xs">
                        Track your progress
                      </h4>
                    </div>
                  </div>
                  <p className="text-neutral-600 font-medium tracking-tight max-w-sm">
                    Unlock the power of data with our advanced analytics tools.
                    Gain actionable insights into customer behavior, market
                    trends, and campaign performance
                  </p>
                </div>
                <div className="p-10 bg-white rounded-5xl">
                  <div className="flex flex-wrap items-center -m-3 mb-5">
                    <div className="w-auto p-3">
                      <div className="flex items-center justify-center w-16 h-16 bg-neutral-900 rounded-full">
                        <img
                          src="mirga-assets/images/features/sticker.svg"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="flex-1 p-3">
                      <h4 className="text-4xl font-medium tracking-tight font-heading max-w-xs">
                        Jobs and Event Opportunities
                      </h4>
                    </div>
                  </div>
                  <p className="text-neutral-600 font-medium tracking-tight max-w-sm">
                    We're here for you around the clock. Our dedicated support
                    team is ready to assist with any questions or concerns you
                    may have.
                  </p>
                </div>
              </div>
              <div className="relative xl:top-40 w-full xl:w-1/3 p-3">
                <div className="flex flex-col h-full justify-center p-10 bg-white rounded-5xl">
                  <div className="flex flex-wrap items-center -m-3 mb-5">
                    <div className="w-auto p-3">
                      <div className="flex items-center justify-center w-16 h-16 bg-neutral-900 rounded-full">
                        <img
                          src="mirga-assets/images/features/sticker.svg"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="flex-1 p-3">
                      <h4 className="text-4xl font-medium tracking-tight font-heading max-w-xs">
                        Be part of the fastest growing community
                      </h4>
                    </div>
                  </div>
                  <p className="text-neutral-600 font-medium tracking-tight max-w-sm">
                    Maximize the effectiveness of your digital campaigns through
                    A/B testing and continuous optimization. We fine-tune your
                    content, ads, and user experience to increase engagement,
                    conversions, and overall success.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-neutral-50 p-5 sm:p-10">
          <div className="px-4 pt-20 md:pt-40 pb-32 md:pb-56 bg-green-100 rounded-5xl">
            <div className="flex flex-col justify-center items-center h-full">
              <h1 className="mb-20 text-center text-neutral-800 text-6xl sm:text-9xl xl:text-11xl font-semibold max-w-5xl mx-auto font-heading">
                Join the community, and change the future.
              </h1>
              <div className="bg-green-400 rounded-6xl p-6 md:p-12">
                <p className="text-6xl sm:text-10xl md:text-11xl lg:text-12xl xl:text-14xl font-semibold tracking-tight text-neutral-800 text-center">
                  <span>1x </span>
                  <span className="text-6xl sm:text-8xl md:text-9xl lg:text-10xl xl:text-12xl">
                  </span>
                  <span>better daily</span>
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-neutral-900 py-12 md:py-24">
          <div className="container mx-auto px-4">
            <h1 className="text-6xl sm:text-9xl xl:text-11xl text-white font-semibold md:mb-20 font-heading">
              FAQ
            </h1>
            <div className="flex justify-center relative">
              <div className="hidden xl:block flex-shrink-0 py-24">
                <div className="flex flex-wrap gap-12">
                  <div className="relative h-64 opacity-30">
                    <img
                      className="rounded-3xl w-52 h-full object-cover opacity-30"
                      src="mirga-assets/images/faq/picture1.png"
                      alt=""
                    />
                    <div className="absolute left-4 bottom-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={48}
                        height={48}
                        viewBox="0 0 48 48"
                        fill="none"
                      >
                        <circle cx={24} cy={24} r={24} fill="#19191B" />
                      </svg>
                      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                        <h3 className="text-white font-semibold font-heading">
                          3
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h2 className="text-white text-3xl sm:text-5xl lg:text-7xl font-semibold tracking-tight mb-10 max-w-xs sm:max-w-sm lg:max-w-xl text-opacity-20 font-heading">
                      How do I measure the success of my digital marketing
                      campaigns?
                    </h2>
                    <p className="text-neutral-300 text-lg sm:text-xl font-medium max-w-md sm:max-w-xl tracking-tight text-opacity-20">
                      Success can be measured through various metrics like
                      website traffic, conversion rates, click-through rates,
                      and ROI. We provide comprehensive analytics and reporting
                      to help you evaluate campaign performance.
                    </p>
                  </div>
                </div>
              </div>
              <div className="xl:flex-shrink-0 w-full max-w-6xl py-24 px-4 md:px-12 xl:mx-24 border-r border-l border-transparent lg:border-neutral-600 relative">
                <div className="flex flex-wrap gap-12">
                  <div className="relative h-64">
                    <img
                      className="rounded-3xl w-52 h-full object-cover"
                      src="mirga-assets/images/faq/picture2.png"
                      alt=""
                    />
                    <div className="absolute left-4 bottom-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={48}
                        height={48}
                        viewBox="0 0 48 48"
                        fill="none"
                      >
                        <circle cx={24} cy={24} r={24} fill="#19191B" />
                      </svg>
                      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                        <h3 className="text-white font-semibold font-heading">
                          1
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h2 className="text-white text-3xl sm:text-5xl lg:text-7xl font-semibold mb-10 max-w-xs tracking-tight sm:max-w-sm lg:max-w-xl font-heading">
                      What is digital marketing, and why is it important for my
                      business?
                    </h2>
                    <p className="text-neutral-300 text-lg sm:text-xl font-medium max-w-md tracking-tight sm:max-w-xl">
                      Digital marketing encompasses various online strategies to
                      promote your business. It's essential because it allows
                      you to reach a wider audience, track performance, and
                      adapt your campaigns for better results.
                    </p>
                  </div>
                </div>
              </div>
              <div className="hidden xl:block flex-shrink-0 py-24">
                <div className="flex flex-wrap gap-12">
                  <div className="relative h-64 opacity-30">
                    <img
                      className="rounded-3xl w-52 h-full object-cover"
                      src="mirga-assets/images/faq/picture3.png"
                      alt=""
                    />
                    <div className="absolute left-4 bottom-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={48}
                        height={48}
                        viewBox="0 0 48 48"
                        fill="none"
                      >
                        <circle cx={24} cy={24} r={24} fill="#19191B" />
                      </svg>
                      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                        <h3 className="text-white font-semibold font-heading">
                          2
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h2 className="text-white text-3xl sm:text-5xl lg:text-7xl font-semibold mb-10 max-w-xs sm:max-w-sm lg:max-w-xl tracking-tight text-opacity-20 font-heading">
                      Do I need a big budget for digital marketing?
                    </h2>
                    <p className="text-neutral-300 text-lg sm:text-xl font-medium max-w-md sm:max-w-xl tracking-tight text-opacity-20">
                      No, digital marketing can be tailored to your budget. We
                      offer flexible packages to meet your needs, whether you're
                      a small startup or a large enterprise. It's all about
                      optimizing resources for the best results
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center gap-4">
              <a
                className="inline-flex items-center justify-center rounded-lg h-16 bg-neutral-800 hover:bg-neutral-900 transition duration-200 px-4 py-6"
                href="#"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={18}
                  height={18}
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9 18L10.2915 16.7463L3.4452 9.9L18 9.9L18 8.1L3.4452 8.1L10.2726 1.2726L9 3.02789e-06L7.86805e-07 9L9 18Z"
                    fill="white"
                  />
                </svg>
              </a>
              <a
                className="inline-flex items-center justify-center rounded-lg h-16 bg-neutral-800 hover:bg-neutral-900 transition duration-200 px-4 py-6"
                href="#"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={18}
                  height={18}
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9 0L7.7085 1.2537L14.5548 8.1L0 8.1L0 9.9L14.5548 9.9L7.7274 16.7274L9 18L18 9L9 0Z"
                    fill="white"
                  />
                </svg>
              </a>
            </div>
          </div>
        </section>
        <section className="py-12 md:py-24 bg-neutral-50">
          <div className="container mx-auto px-4">
            <div className="pb-14 mb-2 border-b border-neutral-900">
              <h1 className="text-6xl sm:text-9xl xl:text-11xl font-semibold font-heading">
                Blog
              </h1>
            </div>
            <p className="text-4xl font-semibold mb-10 tracking-tight">
              The most important news
            </p>
            <div className="bg-white rounded-4xl px-6 mb-6 md:px-12 py-12">
              <span className="inline-block text-neutral-600 text-xl font-semibold mb-6 tracking-tight">
                Big news
              </span>
              <div className="flex flex-wrap -m-4 md:divide-x md:divide-neutral-50">
                <div className="w-full md:w-1/2 lg:w-2/3 p-4">
                  <div className="max-w-xl">
                    <h2 className="mb-16 text-neutral-800 text-6xl sm:text-9xl xl:text-11xl font-semibold font-heading">
                      <span className="mr-3">Your Guide to Higher Search</span>
                      <span className="relative inline-block">
                        <span className="relative z-10">Rankings</span>
                        <span className="absolute -bottom-2 left-0 h-2.5 w-full bg-green-100 rounded-full" />
                      </span>
                    </h2>
                    <p className="text-neutral-600 text-2xl font-medium mb-16 md:mb-32 tracking-tight">
                      Your journey to digital excellence starts here.
                    </p>
                    <div className="flex flex-wrap items-center gap-6 lg:gap-12 mb-6 lg:mb-0">
                      <a
                        className="inline-flex justify-center items-center text-center h-20 p-5 font-semibold tracking-tight text-2xl text-white bg-neutral-900 hover:bg-neutral-800 focus:bg-neutral-800 rounded-lg focus:ring-4 focus:ring-neutral-400 transition duration-200"
                        href="#"
                      >
                        Read more
                      </a>
                      <h2 className="text-neutral-600 text-xl font-semibold tracking-tight font-heading">
                        Thu 12 Oct, 4 AM
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-1/2 lg:w-1/3 p-4">
                  <div className="md:relative md:top-6 md:max-w-sm md:max-h-128 md:mx-auto md:px-3 md:mr-0 w-full h-full">
                    <img
                      className="rounded-4xl mx-auto object-cover"
                      style={{ height: 467 }}
                      src="mirga-assets/images/blog/blog-picture3.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap -m-3 mb-10">
              <div className="w-full md:w-1/2 p-3">
                <div className="bg-white rounded-4xl px-6 md:px-12 py-12 h-full">
                  <div className="flex flex-wrap items-center -m-4 mb-8 lg:mb-18">
                    <div className="w-full lg:w-2/3 p-4">
                      <span className="block text-neutral-600 text-xl font-semibold mb-6 tracking-tight">
                        Big news
                      </span>
                      <a
                        className="block text-neutral-800 text-4xl font-medium hover:underline mb-3 tracking-tight max-w-xs"
                        href="#"
                      >
                        The Rise of Video Marketing: Engage Your Audience Like
                        Never Before!
                      </a>
                      <p className="text-neutral-600 text-lg font-medium mb-11 max-w-xs tracking-tight">
                        Video marketing is taking the digital world by storm.
                      </p>
                      <div className="flex flex-wrap gap-6">
                        <div className="flex items-center gap-2">
                          <div className="w-3 h-3 bg-green-100" />
                          <p className="text-neutral-600 font-medium tracking-tight">
                            design
                          </p>
                        </div>
                        <div className="flex items-center gap-2">
                          <div className="w-3 h-3 bg-orange-200" />
                          <p className="text-neutral-600 font-medium tracking-tight">
                            vid marketing
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="w-full lg:w-1/3 p-4">
                      <img
                        className="mx-auto h-64 object-cover rounded-3xl"
                        src="mirga-assets/images/blog/blog-picture1.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-between flex-wrap gap-4">
                    <p className="text-neutral-600 text-xl font-semibold tracking-tight">
                      Thu 12 Oct, 4 AM
                    </p>
                    <a
                      className="inline-flex justify-center items-center text-center h-16 p-5 font-semibold tracking-tight text-xl hover:text-white focus:text-white bg-white hover:bg-neutral-900 focus:bg-neutral-900 border border-neutral-900 rounded-lg focus:ring-4 focus:ring-neutral-400 transition duration-200"
                      href="#"
                    >
                      Read more
                    </a>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/2 p-3">
                <div className="bg-white rounded-4xl px-6 md:px-12 py-12 h-full">
                  <div className="flex flex-wrap items-center -m-2 mb-8 lg:mb-18">
                    <div className="w-full lg:w-2/3 p-4">
                      <span className="block text-neutral-600 text-xl font-semibold mb-6 tracking-tight">
                        Main room
                      </span>
                      <a
                        className="block text-neutral-800 text-4xl font-medium hover:underline mb-3 tracking-tight max-w-xs"
                        href="#"
                      >
                        Email Marketing Done Right: Crafting Campaigns That
                        Convert
                      </a>
                      <p className="text-neutral-600 text-lg font-medium mb-11 max-w-xs tracking-tight">
                        Email marketing remains a powerful tool for conversions.
                      </p>
                      <div className="flex flex-wrap gap-6">
                        <div className="flex items-center gap-2">
                          <div className="w-3 h-3 bg-purple-50" />
                          <p className="text-neutral-600 font-medium tracking-tight">
                            campaigns
                          </p>
                        </div>
                        <div className="flex items-center gap-2">
                          <div className="w-3 h-3 bg-orange-200" />
                          <p className="text-neutral-600 font-medium tracking-tight">
                            vid marketing
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="w-full lg:w-1/3 p-4">
                      <img
                        className="mx-auto h-64 object-cover rounded-3xl"
                        src="mirga-assets/images/blog/blog-picture2.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-between flex-wrap gap-4">
                    <p className="text-neutral-600 text-xl font-semibold tracking-tight">
                      Thu 11 Oct, 8 AM
                    </p>
                    <a
                      className="inline-flex justify-center items-center text-center h-16 p-5 font-semibold tracking-tight text-xl hover:text-white focus:text-white bg-white hover:bg-neutral-900 focus:bg-neutral-900 border border-neutral-900 rounded-lg focus:ring-4 focus:ring-neutral-400 transition duration-200"
                      href="#"
                    >
                      Read more
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <a
              className="inline-flex justify-center items-center text-center w-full h-16 p-5 font-semibold tracking-tight text-xl hover:text-white focus:text-white bg-transparent hover:bg-neutral-900 focus:bg-neutral-900 border border-neutral-900 rounded-lg focus:ring-4 focus:ring-neutral-400 transition duration-200"
              href="#"
            >
              More articles
            </a>
          </div>
        </section>
        {/* <section className="overflow-hidden">
          <div className="bg-neutral-900">
            <div className="py-3 border-b border-neutral-600">
              <div className="container mx-auto px-4">
                <div className="flex justify-between flex-wrap gap-4">
                  <div className="flex items-center gap-6 sm:gap-18 flex-wrap">
                    <a
                      className="text-white font-medium hover:text-opacity-80 tracking-tight transition duration-200"
                      href="#"
                    >
                      Help Center
                    </a>
                    <a
                      className="text-white font-medium hover:text-opacity-80 tracking-tight transition duration-200"
                      href="#"
                    >
                      Location
                    </a>
                  </div>
                  <div className="flex items-center gap-6 sm:gap-18 flex-wrap">
                    <a
                      className="text-white font-medium hover:text-opacity-80 tracking-tight transition duration-200"
                      href="#"
                    >
                      Contact
                    </a>
                    <div className="flex items-center gap-6 flex-wrap">
                      <a
                        className="text-white font-medium hover:text-opacity-80 tracking-tight transition duration-200"
                        href="#"
                      >
                        Back to top
                      </a>
                      <a
                        className="relative text-white hover:text-opacity-80 transition duration-200"
                        href="#"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={40}
                          height={40}
                          viewBox="0 0 40 40"
                          fill="none"
                        >
                          <circle
                            cx={20}
                            cy={20}
                            r="19.5"
                            transform="rotate(90 20 20)"
                            fill="currentColor"
                            stroke="#E5E5E7"
                          />
                        </svg>
                        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={20}
                            height={20}
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M6.41225 12.1235L9.60066 8.93506L12.7891 12.1235"
                              stroke="#19191B"
                              strokeMiterlimit={10}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-12 pb-20 md:pb-40">
              <div className="container mx-auto px-4">
                <div className="flex flex-wrap -mx-4">
                  <div className="w-full sm:w-1/2 lg:w-1/3 xl:w-1/6 p-4">
                    <h2 className="text-white text-xl font-medium tracking-tight mb-8">
                      Product
                    </h2>
                    <ul className="flex flex-col gap-4">
                      <li>
                        <a
                          className="text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200"
                          href="#"
                        >
                          Design
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200"
                          href="#"
                        >
                          Applications
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200"
                          href="#"
                        >
                          Integrations
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200"
                          href="#"
                        >
                          All Devices
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-neutral-300 font-medium hover:text-neutral-400 tracking-tight focus-within:transition duration-200"
                          href="#"
                        >
                          Design
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200"
                          href="#"
                        >
                          View our demo
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200"
                          href="#"
                        >
                          Chat
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="w-full sm:w-1/2 lg:w-1/3 xl:w-1/6 p-4">
                    <h2 className="text-white text-xl font-medium tracking-tight mb-8">
                      Resources
                    </h2>
                    <ul className="flex flex-col gap-4">
                      <li>
                        <a
                          className="text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200"
                          href="#"
                        >
                          Status
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200"
                          href="#"
                        >
                          Helpdesk
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200"
                          href="#"
                        >
                          Migra Academy
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200"
                          href="#"
                        >
                          Community
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200"
                          href="#"
                        >
                          Events
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200"
                          href="#"
                        >
                          Videos
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200"
                          href="#"
                        >
                          New System
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="w-full sm:w-1/2 lg:w-1/3 xl:w-1/6 p-4">
                    <h2 className="text-white text-xl font-medium tracking-tight mb-8">
                      Company
                    </h2>
                    <ul className="flex flex-col gap-4">
                      <li>
                        <a
                          className="text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200"
                          href="#"
                        >
                          About us
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200"
                          href="#"
                        >
                          Team
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200"
                          href="#"
                        >
                          Careers
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200"
                          href="#"
                        >
                          Customer Stories
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200"
                          href="#"
                        >
                          Blog
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200"
                          href="#"
                        >
                          Media
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200"
                          href="#"
                        >
                          Our Locations
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="w-full sm:w-1/2 lg:w-1/3 xl:w-1/6 p-4">
                    <h2 className="text-white text-xl font-medium tracking-tight mb-8">
                      Solutions
                    </h2>
                    <ul className="flex flex-col gap-4">
                      <li>
                        <a
                          className="text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200"
                          href="#"
                        >
                          Business
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200"
                          href="#"
                        >
                          Consultations
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200"
                          href="#"
                        >
                          Education
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200"
                          href="#"
                        >
                          Contact
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200"
                          href="#"
                        >
                          Contact sales
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200"
                          href="#"
                        >
                          FAQ
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200"
                          href="#"
                        >
                          Live Chat
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="w-full lg:w-1/2 xl:w-1/3 p-4">
                    <h2 className="text-white text-lg font-medium tracking-tight mb-6">
                      Join our Newsletter now
                    </h2>
                    <form className="mb-6" action="#">
                      <div className="flex flex-wrap -m-2">
                        <div className="w-full sm:flex-1 p-2">
                          <input
                            className="w-full px-8 py-5 outline-none rounded-lg placeholder-neutral-600 font-medium focus:ring-4 focus:ring-neutral-100 transition duration-200"
                            type="text"
                            placeholder="Your Email"
                          />
                        </div>
                        <div className="w-full sm:w-auto p-2">
                          <button
                            className="flex justify-center items-center text-center h-full w-full p-5 font-semibold tracking-tight text-lg bg-green-200 hover:bg-green-100 focus:bg-green-100 rounded-lg focus:ring-4 focus:ring-green-200 focus:ring-opacity-50 transition duration-200"
                            type="submit"
                          >
                            More
                          </button>
                        </div>
                      </div>
                    </form>
                    <p className="text-neutral-400 text-sm font-medium max-w-sm tracking-tight">
                      Get the ideas, tools and tips you need to grow your sound
                      straight to your inb
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white py-6">
            <div className="container mx-auto px-4">
              <div className="flex items-center gap-12 flex-wrap">
                <a href="#">
                  <img
                    src="images/1xSkills-Logo-png-1709229383002.webp"
                    alt=""
                    className="h-18"
                  />
                </a>
                <p className="text-neutral-600 font-medium tracking-tight">
                  Made with ❤️ in India © 1 Percent Verse Private Limited 2024.
                  All rights reserved.
                </p>
              </div>
            </div>
          </div>
        </section> */}
      </>
    </React.Fragment>
  );
}
