import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

const meta = {
  title: "",
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Login() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className="bg-white pt-4 pb-12 md:pb-24">
          <div className="container mx-auto px-4">
            <div className="max-w-xl mx-auto">
              <form action="#">
                <h3 className="text-5xl sm:text-7xl xl:text-9xl font-semibold mb-16">
                  Sign in
                </h3>
                <a
                  className="flex justify-center items-center text-center w-full mb-6 p-4 font-semibold tracking-tight hover:text-white border border-neutral-900 bg-white hover:bg-neutral-900 focus:bg-neutral-900 rounded-lg focus:ring-4 focus:ring-neutral-400 transition duration-200"
                  href="#"
                >
                  <img
                    className="mr-6 h-8"
                    src="mirga-assets/images/sign-in/google.svg"
                    alt=""
                  />
                  <span>Continue with Google</span>
                </a>
                <p className="text-center text-neutral-600 font-medium tracking-tight mb-4">
                  OR
                </p>
                <label
                  className="block mb-3 text-neutral-600 font-medium tracking-tight"
                  htmlFor="name"
                >
                  Email address
                </label>
                <input
                  className="w-full mb-6 px-8 py-5 text-lg outline-none rounded-lg border border-neutral-100 placeholder-neutral-300 font-medium focus:ring-4 focus:ring-neutral-100 transition duration-200"
                  id="name"
                  type="text"
                  placeholder="ex. d.duncan@email.com"
                />
                <label
                  className="block mb-3 text-neutral-600 font-medium tracking-tight"
                  htmlFor="password"
                >
                  Password
                </label>
                <input
                  className="w-full mb-4 px-8 py-5 text-lg outline-none rounded-lg border border-neutral-100 placeholder-neutral-300 font-medium focus:ring-4 focus:ring-neutral-100 transition duration-200"
                  id="password"
                  type="password"
                />
                <a
                  className="flex justify-end text-neutral-600 font-medium mb-12 hover:text-neutral-800 tracking-tight transition duration-200"
                  href="#"
                >
                  Forgot my passoword
                </a>
                <button
                  className="flex justify-center items-center text-center w-full mb-6 p-5 font-semibold tracking-tight text-lg text-white bg-neutral-900 hover:bg-neutral-800 focus:bg-neutral-800 rounded-lg focus:ring-4 focus:ring-neutral-400 transition duration-200"
                  type="submit"
                >
                  Register now
                </button>
                <a
                  className="inline-block text-neutral-600 text-sm font-medium hover:text-neutral-800 tracking-tight transition duration-200"
                  href="register"
                  contentEditable="false"
                >
                  Don't have an account? Sign Up
                </a>
              </form>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
