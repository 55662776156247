import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function _404() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className='overflow-hidden'>
          <nav className='relative px-10 py-7 pb-7'>
            <div className='flex items-center justify-between'>
              <a className='relative z-10 pr-4 lg:pr-0' href='#'>
                <img
                  className='h-14'
                  src='images/1xSkills-Logo-png-1709229494497.webp'
                  alt=''
                />
              </a>
              <div className='hidden xl:absolute xl:top-1/2 xl:left-1/2 xl:transform xl:-translate-y-1/2 xl:-translate-x-1/2 xl:flex lg:justify-center gap-7'>
                <a
                  className='flex gap-7 items-center text-lg tracking-tight font-medium hover:text-opacity-80 transition duration-200'
                  href='#'
                >
                  <span>What is 1xSkills</span>
                  <div className='w-1.5 h-1.5 bg-neutral-100 rounded-full' />
                </a>
                <a
                  className='flex gap-7 items-center text-lg tracking-tight font-medium hover:text-opacity-80 transition duration-200'
                  href='#'
                >
                  <span>Features</span>
                  <div className='w-1.5 h-1.5 bg-neutral-100 rounded-full' />
                </a>
                <a
                  className='flex gap-7 items-center text-lg tracking-tight font-medium hover:text-opacity-80 transition duration-200'
                  href='#'
                >
                  <span>Roadmap</span>
                  <div className='w-1.5 h-1.5 bg-neutral-100 rounded-full' />
                </a>
                <a
                  className='flex gap-7 items-center text-lg tracking-tight font-medium hover:text-opacity-80 transition duration-200'
                  href='#'
                >
                  <span>Courses</span>
                  <div className='w-1.5 h-1.5 bg-neutral-100 rounded-full' />
                </a>
                <a
                  className='flex items-center text-lg tracking-tight font-medium hover:text-opacity-80 transition duration-200'
                  href='#'
                >
                  <span>Resources</span>
                </a>
              </div>
              <div className='hidden xl:flex items-center gap-2'>
                <a className='hidden 2xl:block mr-8' href='#'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width={20}
                    height={20}
                    viewBox='0 0 20 20'
                    fill='none'
                  >
                    <g clipPath='url(#clip0_231_6677)'>
                      <path
                        d='M19.6343 17.871L15.7634 13.9984C18.6598 10.1278 17.87 4.64195 13.9994 1.74551C10.1288 -1.15092 4.64292 -0.361157 1.74649 3.50949C-1.14994 7.38013 -0.36018 12.866 3.51046 15.7624C6.61969 18.0891 10.8901 18.0891 13.9994 15.7624L17.872 19.635C18.3587 20.1216 19.1477 20.1216 19.6343 19.635C20.121 19.1483 20.121 18.3593 19.6343 17.8727L19.6343 17.871ZM8.7872 15.015C5.34716 15.015 2.55848 12.2263 2.55848 8.78623C2.55848 5.34618 5.34716 2.55751 8.7872 2.55751C12.2273 2.55751 15.0159 5.34618 15.0159 8.78623C15.0123 12.2247 12.2257 15.0113 8.7872 15.015Z'
                        fill='#19191B'
                      />
                    </g>
                    <defs>
                      <clipPath id='clip0_231_6677'>
                        <rect width={20} height={20} fill='white' />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
                <a
                  className='inline-flex justify-center items-center text-center h-16 p-5 font-semibold tracking-tight text-xl hover:text-white focus:text-white bg-transparent hover:bg-neutral-900 focus:bg-neutral-900 border border-neutral-900 rounded-lg focus:ring-4 focus:ring-neutral-400 transition duration-200'
                  href='#'
                >
                  Login
                </a>
                <a
                  className='inline-flex justify-center items-center text-center h-16 p-5 font-semibold tracking-tight text-xl hover:text-white focus:text-white bg-transparent hover:bg-neutral-900 focus:bg-neutral-900 border border-neutral-900 rounded-lg focus:ring-4 focus:ring-neutral-400 transition duration-200'
                  href='#'
                >
                  Sign up free
                </a>
              </div>
              <a className='navbar-burger xl:hidden' href='#'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width={34}
                  height={14}
                  viewBox='0 0 34 14'
                  fill='none'
                >
                  <g clipPath='url(#clip0_231_4713)'>
                    <rect width={34} height={3} rx='1.5' fill='#19191B' />
                    <rect
                      y={11}
                      width={34}
                      height={3}
                      rx='1.5'
                      fill='#19191B'
                    />
                  </g>
                  <defs>
                    <clipPath id='clip0_231_4713'>
                      <rect width={34} height={14} fill='white' />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </div>
          </nav>
          <div className='navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-xs z-50 hidden'>
            <div className='navbar-backdrop fixed inset-0 bg-black opacity-20' />
            <nav className='relative p-8 w-full h-full bg-white overflow-y-auto'>
              <div className='flex flex-col justify-between h-full'>
                <div>
                  <div className='flex items-center justify-between mb-8'>
                    <a className='pr-4' href='#'>
                      <img
                        className='h-10'
                        src='images/1xSkills-Logo-png-1709229494497.webp'
                        alt=''
                      />
                    </a>
                    <a className='navbar-close' href='#'>
                      <svg
                        width={24}
                        height={24}
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M6 18L18 6M6 6L18 18'
                          stroke='#252E4A'
                          strokeWidth={2}
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    </a>
                  </div>
                  <a href='#'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width={20}
                      height={20}
                      viewBox='0 0 20 20'
                      fill='none'
                    >
                      <g clipPath='url(#clip0_231_6677)'>
                        <path
                          d='M19.6343 17.871L15.7634 13.9984C18.6598 10.1278 17.87 4.64195 13.9994 1.74551C10.1288 -1.15092 4.64292 -0.361157 1.74649 3.50949C-1.14994 7.38013 -0.36018 12.866 3.51046 15.7624C6.61969 18.0891 10.8901 18.0891 13.9994 15.7624L17.872 19.635C18.3587 20.1216 19.1477 20.1216 19.6343 19.635C20.121 19.1483 20.121 18.3593 19.6343 17.8727L19.6343 17.871ZM8.7872 15.015C5.34716 15.015 2.55848 12.2263 2.55848 8.78623C2.55848 5.34618 5.34716 2.55751 8.7872 2.55751C12.2273 2.55751 15.0159 5.34618 15.0159 8.78623C15.0123 12.2247 12.2257 15.0113 8.7872 15.015Z'
                          fill='#19191B'
                        />
                      </g>
                      <defs>
                        <clipPath id='clip0_231_6677'>
                          <rect width={20} height={20} fill='white' />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                </div>
                <div className='flex flex-col gap-8 py-16'>
                  <a
                    className='inline-block max-w-max text-lg tracking-tight font-medium hover:text-opacity-80 transition duration-200'
                    href='#'
                  >
                    What is 1xSkills
                  </a>
                  <a
                    className='inline-block max-w-max text-lg tracking-tight font-medium hover:text-opacity-80 transition duration-200'
                    href='#'
                  >
                    Features
                  </a>
                  <a
                    className='inline-block max-w-max text-lg tracking-tight font-medium hover:text-opacity-80 transition duration-200'
                    href='#'
                  >
                    Roadmap
                  </a>
                  <a
                    className='inline-block max-w-max text-lg tracking-tight font-medium hover:text-opacity-80 transition duration-200'
                    href='#'
                  >
                    Course Catalog
                  </a>
                  <a
                    className='inline-block max-w-max text-lg tracking-tight font-medium hover:text-opacity-80 transition duration-200'
                    href='#'
                  >
                    Resources
                  </a>
                </div>
                <div className='flex flex-col items-center gap-2'>
                  <a
                    className='inline-flex justify-center items-center text-center w-full h-16 p-5 font-semibold tracking-tight text-lg hover:text-white focus:text-white bg-transparent hover:bg-neutral-900 focus:bg-neutral-900 border border-neutral-900 rounded-lg focus:ring-4 focus:ring-neutral-400 transition duration-200'
                    href='#'
                  >
                    Login
                  </a>
                  <a
                    className='inline-flex justify-center items-center text-center w-full h-16 p-5 font-semibold tracking-tight text-lg hover:text-white focus:text-white bg-transparent hover:bg-neutral-900 focus:bg-neutral-900 border border-neutral-900 rounded-lg focus:ring-4 focus:ring-neutral-400 transition duration-200'
                    href='#'
                  >
                    Sign up free
                  </a>
                </div>
              </div>
            </nav>
          </div>
        </section>
        <section className='relative py-12 md:py-24 bg-neutral-50'>
          <div className='container mx-auto px-4'>
            <div className='relative z-10 px-12 py-16 bg-white rounded-4xl'>
              <div className='flex flex-wrap -m-4 divide-y md:divide-y-0 md:divide-x divide-neutral-50'>
                <div className='w-full md:w-2/3 p-4'>
                  <h1 className='relative mb-12 text-5xl sm:text-6xl md:text-9xl xl:text-11xl text-neutral-800 font-semibold font-heading'>
                    <span className='mr-3'>Shh* Something</span>
                    <span className='relative inline-block'>
                      <span className='relative z-10'>has wrong.</span>
                      <span className='absolute -bottom-2 left-0 h-2.5 w-full bg-green-200 rounded-full' />
                    </span>
                  </h1>
                  <p className='mb-12 md:mb-80 text-2xl text-neutral-600 font-semibold tracking-tight'>
                    That page isn’t doesn’t exist or we can’t find it.
                  </p>
                </div>
                <div className='w-full md:w-1/3 p-4'>
                  <div className='py-12 md:py-0'>
                    <div className='max-w-max mx-auto'>
                      <img
                        className='mx-auto object-cover h-64'
                        src='mirga-assets/images/http-codes/face.png'
                        alt=''
                      />
                    </div>
                  </div>
                </div>
              </div>
              <a
                className='inline-flex justify-center items-center text-center h-20 p-5 font-semibold tracking-tight text-2xl text-white bg-neutral-900 hover:bg-neutral-800 focus:bg-neutral-800 rounded-lg focus:ring-4 focus:ring-neutral-400 transition duration-200'
                href='#'
              >
                Homepage
              </a>
            </div>
          </div>
          <img
            className='absolute bottom-0 right-0'
            src='mirga-assets/images/http-codes/404.png'
            alt=''
          />
        </section>
        <section className='overflow-hidden'>
          <div className='bg-neutral-900'>
            <div className='py-3 border-b border-neutral-600'>
              <div className='container mx-auto px-4'>
                <div className='flex justify-between flex-wrap gap-4'>
                  <div className='flex items-center gap-6 sm:gap-18 flex-wrap'>
                    <a
                      className='text-white font-medium hover:text-opacity-80 tracking-tight transition duration-200'
                      href='#'
                    >
                      Help Center
                    </a>
                    <a
                      className='text-white font-medium hover:text-opacity-80 tracking-tight transition duration-200'
                      href='#'
                    >
                      Location
                    </a>
                  </div>
                  <div className='flex items-center gap-6 sm:gap-18 flex-wrap'>
                    <a
                      className='text-white font-medium hover:text-opacity-80 tracking-tight transition duration-200'
                      href='#'
                    >
                      Contact
                    </a>
                    <div className='flex items-center gap-6 flex-wrap'>
                      <a
                        className='text-white font-medium hover:text-opacity-80 tracking-tight transition duration-200'
                        href='#'
                      >
                        Back to top
                      </a>
                      <a
                        className='relative text-white hover:text-opacity-80 transition duration-200'
                        href='#'
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width={40}
                          height={40}
                          viewBox='0 0 40 40'
                          fill='none'
                        >
                          <circle
                            cx={20}
                            cy={20}
                            r='19.5'
                            transform='rotate(90 20 20)'
                            fill='currentColor'
                            stroke='#E5E5E7'
                          />
                        </svg>
                        <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                          >
                            <path
                              d='M6.41225 12.1235L9.60066 8.93506L12.7891 12.1235'
                              stroke='#19191B'
                              strokeMiterlimit={10}
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='pt-12 pb-20 md:pb-40'>
              <div className='container mx-auto px-4'>
                <div className='flex flex-wrap -mx-4'>
                  <div className='w-full sm:w-1/2 lg:w-1/3 xl:w-1/6 p-4'>
                    <h2 className='text-white text-xl font-medium tracking-tight mb-8'>
                      Product
                    </h2>
                    <ul className='flex flex-col gap-4'>
                      <li>
                        <a
                          className='text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200'
                          href='#'
                        >
                          Design
                        </a>
                      </li>
                      <li>
                        <a
                          className='text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200'
                          href='#'
                        >
                          Applications
                        </a>
                      </li>
                      <li>
                        <a
                          className='text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200'
                          href='#'
                        >
                          Integrations
                        </a>
                      </li>
                      <li>
                        <a
                          className='text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200'
                          href='#'
                        >
                          All Devices
                        </a>
                      </li>
                      <li>
                        <a
                          className='text-neutral-300 font-medium hover:text-neutral-400 tracking-tight focus-within:transition duration-200'
                          href='#'
                        >
                          Design
                        </a>
                      </li>
                      <li>
                        <a
                          className='text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200'
                          href='#'
                        >
                          View our demo
                        </a>
                      </li>
                      <li>
                        <a
                          className='text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200'
                          href='#'
                        >
                          Chat
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='w-full sm:w-1/2 lg:w-1/3 xl:w-1/6 p-4'>
                    <h2 className='text-white text-xl font-medium tracking-tight mb-8'>
                      Resources
                    </h2>
                    <ul className='flex flex-col gap-4'>
                      <li>
                        <a
                          className='text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200'
                          href='#'
                        >
                          Status
                        </a>
                      </li>
                      <li>
                        <a
                          className='text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200'
                          href='#'
                        >
                          Helpdesk
                        </a>
                      </li>
                      <li>
                        <a
                          className='text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200'
                          href='#'
                        >
                          Migra Academy
                        </a>
                      </li>
                      <li>
                        <a
                          className='text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200'
                          href='#'
                        >
                          Community
                        </a>
                      </li>
                      <li>
                        <a
                          className='text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200'
                          href='#'
                        >
                          Events
                        </a>
                      </li>
                      <li>
                        <a
                          className='text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200'
                          href='#'
                        >
                          Videos
                        </a>
                      </li>
                      <li>
                        <a
                          className='text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200'
                          href='#'
                        >
                          New System
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='w-full sm:w-1/2 lg:w-1/3 xl:w-1/6 p-4'>
                    <h2 className='text-white text-xl font-medium tracking-tight mb-8'>
                      Company
                    </h2>
                    <ul className='flex flex-col gap-4'>
                      <li>
                        <a
                          className='text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200'
                          href='#'
                        >
                          About us
                        </a>
                      </li>
                      <li>
                        <a
                          className='text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200'
                          href='#'
                        >
                          Team
                        </a>
                      </li>
                      <li>
                        <a
                          className='text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200'
                          href='#'
                        >
                          Careers
                        </a>
                      </li>
                      <li>
                        <a
                          className='text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200'
                          href='#'
                        >
                          Customer Stories
                        </a>
                      </li>
                      <li>
                        <a
                          className='text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200'
                          href='#'
                        >
                          Blog
                        </a>
                      </li>
                      <li>
                        <a
                          className='text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200'
                          href='#'
                        >
                          Media
                        </a>
                      </li>
                      <li>
                        <a
                          className='text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200'
                          href='#'
                        >
                          Our Locations
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='w-full sm:w-1/2 lg:w-1/3 xl:w-1/6 p-4'>
                    <h2 className='text-white text-xl font-medium tracking-tight mb-8'>
                      Solutions
                    </h2>
                    <ul className='flex flex-col gap-4'>
                      <li>
                        <a
                          className='text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200'
                          href='#'
                        >
                          Business
                        </a>
                      </li>
                      <li>
                        <a
                          className='text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200'
                          href='#'
                        >
                          Consultations
                        </a>
                      </li>
                      <li>
                        <a
                          className='text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200'
                          href='#'
                        >
                          Education
                        </a>
                      </li>
                      <li>
                        <a
                          className='text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200'
                          href='#'
                        >
                          Contact
                        </a>
                      </li>
                      <li>
                        <a
                          className='text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200'
                          href='#'
                        >
                          Contact sales
                        </a>
                      </li>
                      <li>
                        <a
                          className='text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200'
                          href='#'
                        >
                          FAQ
                        </a>
                      </li>
                      <li>
                        <a
                          className='text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200'
                          href='#'
                        >
                          Live Chat
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='w-full lg:w-1/2 xl:w-1/3 p-4'>
                    <h2 className='text-white text-lg font-medium tracking-tight mb-6'>
                      Join our Newsletter now
                    </h2>
                    <form className='mb-6' action='#'>
                      <div className='flex flex-wrap -m-2'>
                        <div className='w-full sm:flex-1 p-2'>
                          <input
                            className='w-full px-8 py-5 outline-none rounded-lg placeholder-neutral-600 font-medium focus:ring-4 focus:ring-neutral-100 transition duration-200'
                            type='text'
                            placeholder='Your Email'
                          />
                        </div>
                        <div className='w-full sm:w-auto p-2'>
                          <button
                            className='flex justify-center items-center text-center h-full w-full p-5 font-semibold tracking-tight text-lg bg-green-200 hover:bg-green-100 focus:bg-green-100 rounded-lg focus:ring-4 focus:ring-green-200 focus:ring-opacity-50 transition duration-200'
                            type='submit'
                          >
                            More
                          </button>
                        </div>
                      </div>
                    </form>
                    <p className='text-neutral-400 text-sm font-medium max-w-sm tracking-tight'>
                      Get the ideas, tools and tips you need to grow your sound
                      straight to your inb
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='bg-white py-6'>
            <div className='container mx-auto px-4'>
              <div className='flex items-center gap-12 flex-wrap'>
                <a href='#'>
                  <img
                    src='images/1xSkills-Logo-png-1709229383002.webp'
                    alt=''
                    className='h-14'
                  />
                </a>
                <p className='text-neutral-600 font-medium tracking-tight'>
                  Made with ❤️ in India © 1 Percent Verse Private Limited 2024.
                  All rights reserved.
                </p>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}

