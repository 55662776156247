import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

const meta = {
  title: "",
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Register() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className="bg-neutral-50 py-12 md:py-24">
          <div className="container mx-auto px-4">
            <div className="bg-white rounded-3xl overflow-hidden">
              <div className="flex flex-wrap">
                <div className="w-full xl:w-1/2">
                  <div style={{ height: 1100 }}>
                    <img
                      className="h-full w-full object-cover"
                      src="mirga-assets/images/sign-in/woman-picture1.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="w-full xl:w-1/2">
                  <div className="px-8 md:px-16 pt-16 pb-20">
                    <form action="#">
                      <span className="inline-block text-neutral-600 text-xl font-semibold tracking-tight mb-4">
                        Account
                      </span>
                      <h1 className="text-6xl font-semibold tracking-tight mb-6 font-heading">
                        Get Started for free
                      </h1>
                      <p className="text-neutral-600 font-medium tracking-tight mb-16">
                        Sign up to access your exclusive access to our platform
                      </p>
                      <a
                        className="flex justify-center items-center text-center w-full mb-6 p-4 font-semibold tracking-tight hover:text-white border border-neutral-900 bg-white hover:bg-neutral-900 focus:bg-neutral-900 rounded-lg focus:ring-4 focus:ring-neutral-400 transition duration-200"
                        href="#"
                      >
                        <img
                          className="mr-6 h-8"
                          src="mirga-assets/images/sign-in/google.svg"
                          alt=""
                        />
                        <span>Continue with Google</span>
                      </a>
                      <p className="text-center text-neutral-600 font-medium mb-4">
                        OR
                      </p>
                      <div>
                        <div className="flex flex-wrap -m-3 mb-3">
                          <div className="w-full p-3">
                            {/* Name Input */}
                            <label
                              className="block mb-1 text-neutral-600 font-medium"
                              htmlFor="name"
                            >
                              Name
                            </label>
                            <input
                              className="w-full px-8 py-5 outline-none rounded-lg border border-neutral-100 placeholder-neutral-300 font-medium focus:ring-4 focus:ring-neutral-100 transition duration-200"
                              id="name"
                              type="text"
                              placeholder="ex. John Doe"
                            />
                          </div>

                          <div className="w-full p-3">
                            {/* Phone Input */}
                            <label
                              className="block mb-1 text-neutral-600 font-medium"
                              htmlFor="phone"
                            >
                              Phone
                            </label>
                            <input
                              className="w-full px-8 py-5 outline-none rounded-lg border border-neutral-100 placeholder-neutral-300 font-medium focus:ring-4 focus:ring-neutral-100 transition duration-200"
                              id="phone"
                              type="tel"
                              placeholder="ex. 555-555-5555"
                            />
                          </div>

                          <div className="w-full p-3">
                            {/* Email Input */}
                            <label
                              className="block mb-1 text-neutral-600 font-medium"
                              htmlFor="email"
                            >
                              Email
                            </label>
                            <input
                              className="w-full px-8 py-5 outline-none rounded-lg border border-neutral-100 placeholder-neutral-300 font-medium focus:ring-4 focus:ring-neutral-100 transition duration-200"
                              id="email"
                              type="email"
                              placeholder="ex. john@example.com"
                            />
                          </div>

                          <div className="w-full p-3">
                            {/* Password Input */}
                            <label
                              className="block mb-1 text-neutral-600 font-medium"
                              htmlFor="password"
                            >
                              Password
                            </label>
                            <input
                              className="w-full px-8 py-5 outline-none rounded-lg border border-neutral-100 placeholder-neutral-300 font-medium focus:ring-4 focus:ring-neutral-100 transition duration-200"
                              id="password"
                              type="password"
                              placeholder="Enter your password"
                            />
                          </div>

                          <div className="w-full p-3">
                            {/* Submit Button */}
                            <button
                              className="flex justify-center items-center text-center h-full w-full p-5 font-semibold tracking-tight text-lg text-white bg-neutral-900 hover:bg-neutral-800 focus:bg-neutral-800 rounded-lg focus:ring-4 focus:ring-neutral-400 transition duration-200"
                              type="submit"
                            >
                              Register now
                            </button>
                          </div>
                        </div>
                      </div>
                      <a
                        className="inline-block text-neutral-600 text-sm font-medium hover:text-neutral-800 tracking-tight transition duration-200"
                        href="login"
                      >
                        Already have an account? Log in
                      </a>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
