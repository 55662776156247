import React from "react";

const solution = [
  {
    title: "Business",
    href: "#",
  },
  {
    title: "Consultations",
    href: "#",
  },
  {
    title: "Education",
    href: "#",
  },
  {
    title: "Contact",
    href: "#",
  },
  {
    title: "Contact sales",
    href: "#",
  },
  {
    title: "FAQ",
    href: "#",
  },
  {
    title: "Live Chat",
    href: "#",
  },
];

const company = [
  {
    title: "About us",
    href: "#",
  },
  {
    title: "Team",
    href: "#",
  },
  {
    title: "Careers",
    href: "#",
  },
  {
    title: "Customer Stories",
    href: "#",
  },
  {
    title: "Blog",
    href: "#",
  },
  {
    title: "Media",
    href: "#",
  },
  {
    title: "Our Locations",
    href: "#",
  },
];

const resources = [
  {
    title: "Status",
    href: "#",
  },
  {
    title: "Helpdesk",
    href: "#",
  },
  {
    title: "Migra Academy",
    href: "#",
  },
  {
    title: "Community",
    href: "#",
  },
  {
    title: "Events",
    href: "#",
  },
  {
    title: "Videos",
    href: "#",
  },
  {
    title: "New System",
    href: "#",
  },
];

const product = [
  {
    title: "Design",
    href: "#",
  },
  {
    title: "Applications",
    href: "#",
  },
  {
    title: "Integrations",
    href: "#",
  },
  {
    title: "All Devices",
    href: "#",
  },
  {
    title: "Design",
    href: "#",
  },
  {
    title: "View our demo",
    href: "#",
  },
  {
    title: "Chat",
    href: "#",
  },
];

const footerlinks = [
  {
    title: "Product",
    items: product,
  },
  {
    title: "Resources",
    items: resources,
  },
  {
    title: "Company",
    items: company,
  },
  {
    title: "Solutions",
    items: solution,
  },
];

const FooterLink = ({ title, href }) => {
  return (
    <a
      className="text-neutral-300 font-medium hover:text-neutral-400 tracking-tight transition duration-200"
      href={href}
    >
      {title}
    </a>
  );
};

const FooterNavBar = ({ title, items }) => {
  return (
    <div className="w-full sm:w-1/2 lg:w-1/3 xl:w-1/6 p-4">
      <h2 className="text-white text-xl font-medium tracking-tight mb-8">
        {title}
      </h2>
      <ul className="flex flex-col gap-4">
        {items.map((item, index) => (
          <li key={index}>
            <FooterLink {...item} />
          </li>
        ))}
      </ul>
    </div>
  );
};

const Footer = () => {
  return (
    <section className="overflow-hidden">
      <div className="bg-neutral-900">
        <div className="py-3 border-b border-neutral-600">
          <div className="container mx-auto px-4">
            <div className="flex justify-between flex-wrap gap-4">
              <div className="flex items-center gap-6 sm:gap-18 flex-wrap">
                <a
                  className="text-white font-medium hover:text-opacity-80 tracking-tight transition duration-200"
                  href="#"
                >
                  Help Center
                </a>
                <a
                  className="text-white font-medium hover:text-opacity-80 tracking-tight transition duration-200"
                  href="#"
                >
                  Location
                </a>
              </div>
              <div className="flex items-center gap-6 sm:gap-18 flex-wrap">
                <a
                  className="text-white font-medium hover:text-opacity-80 tracking-tight transition duration-200"
                  href="#"
                >
                  Contact
                </a>
                <div className="flex items-center gap-6 flex-wrap">
                  <a
                    className="text-white font-medium hover:text-opacity-80 tracking-tight transition duration-200"
                    href="#"
                  >
                    Back to top
                  </a>
                  <a
                    className="relative text-white hover:text-opacity-80 transition duration-200"
                    href="#"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={40}
                      height={40}
                      viewBox="0 0 40 40"
                      fill="none"
                    >
                      <circle
                        cx={20}
                        cy={20}
                        r="19.5"
                        transform="rotate(90 20 20)"
                        fill="currentColor"
                        stroke="#E5E5E7"
                      />
                    </svg>
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={20}
                        height={20}
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M6.41225 12.1235L9.60066 8.93506L12.7891 12.1235"
                          stroke="#19191B"
                          strokeMiterlimit={10}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-12 pb-20 md:pb-40">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap -mx-4">
              {footerlinks.map((footerlink, index) => (
                <FooterNavBar key={index} {...footerlink} />
              ))}
              <div className="w-full lg:w-1/2 xl:w-1/3 p-4">
                <h2 className="text-white text-lg font-medium tracking-tight mb-6">
                  Join our Newsletter now
                </h2>
                <form className="mb-6" action="#">
                  <div className="flex flex-wrap -m-2">
                    <div className="w-full sm:flex-1 p-2">
                      <input
                        className="w-full px-8 py-5 outline-none rounded-lg placeholder-neutral-600 font-medium focus:ring-4 focus:ring-neutral-100 transition duration-200"
                        type="text"
                        placeholder="Your Email"
                      />
                    </div>
                    <div className="w-full sm:w-auto p-2">
                      <button
                        className="flex justify-center items-center text-center h-full w-full p-5 font-semibold tracking-tight text-lg bg-green-200 hover:bg-green-100 focus:bg-green-100 rounded-lg focus:ring-4 focus:ring-green-200 focus:ring-opacity-50 transition duration-200"
                        type="submit"
                      >
                        More
                      </button>
                    </div>
                  </div>
                </form>
                <p className="text-neutral-400 text-sm font-medium max-w-sm tracking-tight">
                  Get the ideas, tools and tips you need to grow your sound
                  straight to your inb
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white py-6">
        <div className="container mx-auto px-4">
          <div className="flex items-center gap-12 flex-wrap">
            <a href="#">
              <img
                src="images/1xSkills-Logo-png-1709229383002.webp"
                alt=""
                className="h-18 "
              />
            </a>
            <p className="text-neutral-600 font-medium tracking-tight">
              Made with ❤️ in India © 1 Percent Verse Private Limited 2024. All
              rights reserved.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
