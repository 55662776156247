import React from "react";
import { HiBars2 } from "react-icons/hi2";
import { RxCross2 } from "react-icons/rx";
import { Link } from "react-router-dom";

const navlinks = [
  {
    title: "What is 1xSkills",
    href: "/",
  },
  {
    title: "Features",
    href: "/",
  },
  {
    title: "Roadmap",
    href: "/",
  },
  {
    title: "Course Catalog",
    href: "/",
  },
  {
    title: "Resources",
    href: "/",
  },
];

const DesktopNavlink = ({ title, href }) => {
  return (
    <Link
      className="flex gap-7 items-center text-lg tracking-tight font-medium hover:text-opacity-80 transition duration-200"
      to={href}
    >
      <span>{title}</span>
      <div className="w-1.5 h-1.5 bg-neutral-100 rounded-full" />
    </Link>
  );
};

const Navbar = () => {
  const [isNavbarOpen, setIsNavbarOpen] = React.useState(false);

  const handleNavOpen = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  return (
    <section className="sticky top-0 z-50 bg-white overflow-hidden">
      <nav className="relative px-10 py-7 pb-7">
        <div className="flex items-center justify-between">
          <Link className="relative z-10 pr-4 lg:pr-0" to="/">
            <img
              className="h-14"
              src="images/1xSkills-Logo-png-1709229494497.webp"
              alt=""
            />
          </Link>
          <div className="hidden xl:absolute xl:top-1/2 xl:left-1/2 xl:transform xl:-translate-y-1/2 xl:-translate-x-1/2 xl:flex lg:justify-center gap-7">
            {navlinks.map((navlink) => (
              <DesktopNavlink key={navlink.title} {...navlink} />
            ))}
          </div>
          <div className="hidden xl:flex items-center gap-2">
            <Link className="hidden 2xl:block mr-8" to="/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={20}
                height={20}
                viewBox="0 0 20 20"
                fill="none"
              >
                <g clipPath="url(#clip0_231_6677)">
                  <path
                    d="M19.6343 17.871L15.7634 13.9984C18.6598 10.1278 17.87 4.64195 13.9994 1.74551C10.1288 -1.15092 4.64292 -0.361157 1.74649 3.50949C-1.14994 7.38013 -0.36018 12.866 3.51046 15.7624C6.61969 18.0891 10.8901 18.0891 13.9994 15.7624L17.872 19.635C18.3587 20.1216 19.1477 20.1216 19.6343 19.635C20.121 19.1483 20.121 18.3593 19.6343 17.8727L19.6343 17.871ZM8.7872 15.015C5.34716 15.015 2.55848 12.2263 2.55848 8.78623C2.55848 5.34618 5.34716 2.55751 8.7872 2.55751C12.2273 2.55751 15.0159 5.34618 15.0159 8.78623C15.0123 12.2247 12.2257 15.0113 8.7872 15.015Z"
                    fill="#19191B"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_231_6677">
                    <rect width={20} height={20} fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </Link>
            <Link
              className="inline-flex justify-center items-center text-center h-16 p-5 font-semibold tracking-tight text-xl hover:text-white focus:text-white bg-transparent hover:bg-neutral-900 focus:bg-neutral-900 border border-neutral-900 rounded-lg focus:ring-4 focus:ring-neutral-400 transition duration-200"
              to="/login"
            >
              Login
            </Link>
            <Link
              className="inline-flex justify-center items-center text-center h-16 p-5 font-semibold tracking-tight text-xl hover:text-white focus:text-white bg-transparent hover:bg-neutral-900 focus:bg-neutral-900 border border-neutral-900 rounded-lg focus:ring-4 focus:ring-neutral-400 transition duration-200"
              to="/register"
            >
              Sign up free
            </Link>
          </div>
          <div onClick={handleNavOpen} className="xl:hidden text-4xl">
            {!isNavbarOpen && <HiBars2 />}
          </div>
        </div>
      </nav>
      <div
        className={`navbar-menu fixed top-0 left-0 bottom-0 ease-in-out w-5/6 max-w-xs z-50 ${
          isNavbarOpen ? "block" : "hidden"
        }`}
      >
        <div
          className="navbar-backdrop fixed inset-0 bg-black opacity-20"
          onClick={handleNavOpen}
        />
        <nav className="relative p-8 w-full h-full bg-white overflow-y-auto">
          <div className="flex flex-col justify-between h-full">
            <div>
              <div className="flex items-center justify-between mb-8">
                <Link className="pr-4" to="/">
                  <img
                    className="h-10"
                    src="images/1xSkills-Logo-png-1709229494497.webp"
                    alt=""
                  />
                </Link>
                <div onClick={handleNavOpen} className="navbar-close">
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 18L18 6M6 6L18 18"
                      stroke="#252E4A"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
              {navlinks.map((navlink) => (
                <Link
                  key={navlink.title}
                  className="block py-2"
                  to={navlink.href}
                  onClick={handleNavOpen}
                >
                  {navlink.title}
                </Link>
              ))}
            </div>
            <div className="flex flex-col items-center gap-2">
              <Link
                className="inline-flex justify-center items-center text-center w-full h-16 p-5 font-semibold tracking-tight text-lg hover:text-white focus:text-white bg-transparent hover:bg-neutral-900 focus:bg-neutral-900 border border-neutral-900 rounded-lg focus:ring-4 focus:ring-neutral-400 transition duration-200"
                to="/login"
                onClick={handleNavOpen}
              >
                Login
              </Link>
              <Link
                className="inline-flex justify-center items-center text-center w-full h-16 p-5 font-semibold tracking-tight text-lg hover:text-white focus:text-white bg-transparent hover:bg-neutral-900 focus:bg-neutral-900 border border-neutral-900 rounded-lg focus:ring-4 focus:ring-neutral-400 transition duration-200"
                to="/register"
                onClick={handleNavOpen}
              >
                Sign up free
              </Link>
            </div>
          </div>
        </nav>
      </div>
    </section>
  );
};

export default Navbar;
