import React from "react";
import { Routes, Route } from "react-router-dom";

import IndexPage from "./pages/Index.js";
import LoginPage from "./pages/Login.js";
import ContactPage from "./pages/Contact.js";
import RegisterPage from "./pages/Register.js";
import _404Page from "./pages/404.js";
import Navbar from "./components/Navbar.jsx";
import Footer from "./components/Footer.jsx";

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<IndexPage />} />

        <Route path="/login" element={<LoginPage />} />

        <Route path="/contact" element={<ContactPage />} />

        <Route path="/register" element={<RegisterPage />} />

        <Route path="/404" element={<_404Page />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
